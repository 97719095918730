import React from 'react';
import { useSelector } from 'react-redux';
import ExcelJS from 'exceljs';
import { uniqBy } from 'lodash';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { notificationError } from '../../helpers/notification';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { ACTIVITY_STATE, dateToJsDate } from '../../helpers/planning';
import DefaultUnifiedFieldId from '../../constants/DefaultPlanningField';
import { LINK_CATEGORY, LINK_TYPES } from '../../constants/Links';
import { selectGanttData } from '../../redux/slices/app/planning.slice';
import getDurationUnitName, { getQuantityUnitName } from '../plannings/gantt_utils';
import { selectActivityEntities } from '../../redux/slices/app/activity.slice';
import { selectTimeUnits } from '../../redux/slices/app/calendar.slice';
import quantityUnits from '../plannings/activity_config';

const QuickButton = styled.button`
    width: 32px;
    height: 32px;
`;

const ExportMenu = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'export' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const columnsTranslation = useTranslation('translation', { keyPrefix: 'columns' }).t;
    const linksTranslation = useTranslation('translation', { keyPrefix: 'link_tab' }).t;
    const activityTabTranslation = useTranslation('translation', { keyPrefix: 'activity_tab' }).t;
    const { planningSelected, calendarsDictionary, planningCustomFields } = useSelector(selectGanttData);
    const activitiesDictionary = useSelector(selectActivityEntities);
    const timeUnits = useSelector(selectTimeUnits);
    

    // const getYieldUnit = (quantityId, durationId) =>
    //     `${getQuantityUnitName(quantityId)}/${getDurationUnitName(durationId)}`;

    const formatExportGanttData = (data) =>
        data.map((task) => {
            console.log('task', task);
            const taskDataToExport = {};
            taskDataToExport[columnsTranslation('id')] = task.identity;
            taskDataToExport[columnsTranslation('parent')] =
                task.serverId !== planningSelected.rootActivityId ? activitiesDictionary[task.data_api.activityParentId].identity : '';
            taskDataToExport[columnsTranslation('name')] = task.text;
            taskDataToExport[columnsTranslation('description')] = task.description;
            taskDataToExport[columnsTranslation('jobs')] = (task.jobId || [])
                .map((job) => {
                    if (job === ALL_JOBS) {
                        return generalTranslation('all_jobs', { lng: 'en' });
                    }
                    return job;
                })
                .join(';');
            taskDataToExport[columnsTranslation('calendar')] = calendarsDictionary?.[task.calendarId]?.name;
            taskDataToExport[columnsTranslation('start_date')] = dateToJsDate(task.startDate, null, false);
            taskDataToExport[columnsTranslation('end_date')] = dateToJsDate(task.endDate, null, false);
            taskDataToExport[columnsTranslation('duration')] = task.durationApi
                ? Number(task.durationApi).toFixed(2)
                : Number(0);
            taskDataToExport[columnsTranslation('duration_unit')] = getDurationUnitName(task.dayDefinitionId);
            taskDataToExport[columnsTranslation('progress')] = Number(task.progress * 100);
            const calculUnifiedField = {
                [DefaultUnifiedFieldId.duration]: 'Duration',
                [DefaultUnifiedFieldId.quantity]: 'Quantity',
                [DefaultUnifiedFieldId.yield]: 'Workrate',
            };
            taskDataToExport[columnsTranslation('calcul')] = calculUnifiedField[Number(task.champPMAutoId)];
            taskDataToExport[activityTabTranslation('round_duration')] =
                activitiesDictionary[task.serverId].roundedDuration ? 1 : 0;
            taskDataToExport[columnsTranslation('quantity')] = Number(task.quantity).toFixed(2);
            taskDataToExport[columnsTranslation('quantity_unit')] = getQuantityUnitName(task.quantityUnit, 'value');

            taskDataToExport[columnsTranslation('yield')] = Number(task.yield).toFixed(2);
            taskDataToExport[columnsTranslation('state')] = task.status;

            // user fields

            task.customFields?.forEach((customField) => {
                if (customField.name !== JOB_FIELD) {
                    // eslint-disable-next-line
                    taskDataToExport[customField.name] = customField.value[0];
                }
            });

            return taskDataToExport;
        });

    const LinkTypes = LINK_TYPES(i18n, 'en');
    const LinkCategories = LINK_CATEGORY(i18n, 'en');

    const formatLinkData = (data) =>
        data.map((link) => {
            const linkDataToExport = {};
            linkDataToExport[t('predecessor_id')] = activitiesDictionary[link.activityPredecessorId].identity;
            linkDataToExport[t('successor_id')] = activitiesDictionary[link.activitySuccessorId].identity;
            linkDataToExport[generalTranslation('type')] = LinkTypes[link.type].label;
            linkDataToExport[linksTranslation('gap')] = link.decalage;
            linkDataToExport[linksTranslation('gap_unit')] = getDurationUnitName(link.dayDefinitionId);
            linkDataToExport[linksTranslation('link_category')] = LinkCategories[link.category.name].label;
            linkDataToExport[linksTranslation('skip_link')] = !link.used ? 1 : 0;
            // linkDataToExport[linksTranslation('gap_unit')] = timeUnits.find(
            //     (item) => item.id === link.dayDefinitionId
            // )?.name;
            return linkDataToExport;
        });

    /**
     * Creates a hidden validation sheet with all necessary validation lists
     * @param {ExcelJS.Workbook} workbook - The Excel workbook
     * @returns {Object} Validation values and their ranges
     */
    const createValidationSheet = (workbook) => {
        const validationSheet = workbook.addWorksheet('ValidationLists');
        validationSheet.state = 'hidden';  // Hide the validation sheet from users
        
        // Create validation list for Link Types (FD, DD, FF, DF)
        validationSheet.getCell('B1').value = 'LinkTypes';
        const linkTypeValues = Object.values(LinkTypes).map(lt => lt.label);
        linkTypeValues.forEach((value, index) => {
            validationSheet.getCell(`B${index + 2}`).value = value;
        });

        // Create validation list for Link Categories (Normal, Conflict, Dominant)
        validationSheet.getCell('C1').value = 'LinkCategories';
        const linkCategoryValues = Object.values(LinkCategories).map(lc => lc.label);
        linkCategoryValues.forEach((value, index) => {
            validationSheet.getCell(`C${index + 2}`).value = value;
        });

        // Create validation list for Boolean values (0, 1)
        validationSheet.getCell('D1').value = 'BooleanValues';
        [0, 1].forEach((value, index) => {
            validationSheet.getCell(`D${index + 2}`).value = value;
        });

        // Create validation list for Time Units
        validationSheet.getCell('E1').value = 'TimeUnits';
        const timeUnitValues = timeUnits.map(tu => tu.name);
        timeUnitValues.forEach((value, index) => {
            validationSheet.getCell(`E${index + 2}`).value = value;
        });

        // Add validation for Calcul field (Duration, Quantity, Workrate)
        validationSheet.getCell('F1').value = 'CalculTypes';
        const calculTypes = ['Duration', 'Quantity', 'Workrate'];
        calculTypes.forEach((value, index) => {
            validationSheet.getCell(`F${index + 2}`).value = value;
        });

        // Add validation for Quantity Units
        validationSheet.getCell('G1').value = 'QuantityUnits';
        const quantityUnitValues = quantityUnits.map(unit => unit.value);
        quantityUnitValues.forEach((value, index) => {
            validationSheet.getCell(`G${index + 2}`).value = value;
        });

        // Add validation for Calendar names
        validationSheet.getCell('H1').value = 'Calendars';
        const calendarValues = Object.values(calendarsDictionary).map(cal => cal.name);
        calendarValues.forEach((value, index) => {
            validationSheet.getCell(`H${index + 2}`).value = value;
        });

        // Add validation for Jobs
        validationSheet.getCell('I1').value = 'Jobs';
        const jobCustomField = planningCustomFields.find((i) => i.name === JOB_FIELD);
        const jobValues = Object.keys(jobCustomField.type.choices).map(jobName => 
            jobName === ALL_JOBS ? generalTranslation('all_jobs', { lng: 'en' }) : jobName
        );
        jobValues.forEach((value, index) => {
            validationSheet.getCell(`I${index + 2}`).value = value;
        });

        // Add validation for Status
        validationSheet.getCell('J1').value = 'Status';
        const statusValues = Object.values(ACTIVITY_STATE);
        statusValues.forEach((value, index) => {
            validationSheet.getCell(`J${index + 2}`).value = value;
        });

        return {
            timeUnitValues,
            validationRanges: {
                linkTypes: `ValidationLists!$B$2:$B$${linkTypeValues.length + 1}`,
                linkCategories: `ValidationLists!$C$2:$C$${linkCategoryValues.length + 1}`,
                booleans: 'ValidationLists!$D$2:$D$3',
                timeUnits: `ValidationLists!$E$2:$E$${timeUnitValues.length + 1}`,
                calculTypes: `ValidationLists!$F$2:$F$${calculTypes.length + 1}`,
                quantityUnits: `ValidationLists!$G$2:$G$${quantityUnitValues.length + 1}`,
                calendars: `ValidationLists!$H$2:$H$${calendarValues.length + 1}`,
                jobs: `ValidationLists!$I$2:$I$${jobValues.length + 1}`,
                status: `ValidationLists!$J$2:$J$${statusValues.length + 1}`
            }
        };
    };

    /**
     * Sets validation rules on a cell
     * @param {ExcelJS.Cell} cell - The cell to set validation on
     * @param {Object} validationRule - The validation rule to apply
     */
    const setCellValidation = (cell, validationRule) => {
        const newCell = cell;
        newCell.dataValidation = {
            ...validationRule,
            showErrorMessage: false,  // Remove error messages
            showInputMessage: true,   // Keep the helpful prompts
            allowBlank: true,         // Always allow blank values
        };
    };

    /**
     * Adds data validation rules to the Links worksheet
     * @param {ExcelJS.Worksheet} linkSheet - The Links worksheet
     * @param {Array} links - Links data for column reference
     * @param {Object} validationData - Validation ranges and values
     */
    const addLinksValidation = (linkSheet, links, validationData) => {
        const { validationRanges } = validationData;

        // Update link types validation to be informational
        const typeColIndex = Object.keys(links[0]).indexOf(generalTranslation('type')) + 1;
        linkSheet.getColumn(typeColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.linkTypes],
                    promptTitle: 'Link Type',
                    prompt: 'Suggested values are available in the dropdown'
                });
            }
        });

        // Update gap units validation to be informational
        const gapUnitColIndex = Object.keys(links[0]).indexOf(linksTranslation('gap_unit')) + 1;
        linkSheet.getColumn(gapUnitColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.timeUnits],
                    promptTitle: 'Time Unit',
                    prompt: 'Suggested time units are available in the dropdown'
                });
            }
        });

        // Update gap validation to be informational
        const gapColIndex = Object.keys(links[0]).indexOf(linksTranslation('gap')) + 1;
        linkSheet.getColumn(gapColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'decimal',
                    promptTitle: 'Gap',
                    prompt: 'Enter a numeric value for the gap'
                });
            }
        });

        // Update link category validation to be informational
        const linkCategoryColIndex = Object.keys(links[0]).indexOf(linksTranslation('link_category')) + 1;
        linkSheet.getColumn(linkCategoryColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.linkCategories],
                    promptTitle: 'Link Category',
                    prompt: 'Suggested categories are available in the dropdown'
                });
            }
        });
    };

    /**
     * Sets cell styling
     * @param {ExcelJS.Cell} cell - The cell to style
     */
    const setCellStyle = (cell) => {
        const newCell = cell;
        newCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FBBC05' }
        };
        newCell.font = {
            bold: true,
            size: 12
        };
        newCell.border = {
            bottom: { style: 'thin' }
        };
        newCell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
        };
    };

    /**
     * Applies consistent styling to worksheet headers
     * @param {Array<ExcelJS.Worksheet>} sheets - Array of worksheets to style
     */
    const styleSheets = (sheets) => {
        sheets.forEach(sheet => {
            const headerRow = sheet.getRow(1);
            headerRow.eachCell(setCellStyle);
            headerRow.height = 20;
        });
    };
            /* eslint-disable */

    /**
     * Adds data validation rules to the Tasks worksheet
     * @param {ExcelJS.Worksheet} taskSheet - The Tasks worksheet
     * @param {Array} tasks - Tasks data for column reference
     * @param {Object} validationData - Validation ranges and values
     */
    const addTasksValidation = (taskSheet, tasks, validationData) => {
        const { validationRanges } = validationData;

        // Add validation for calcul field
        const calculColIndex = Object.keys(tasks[0]).indexOf(columnsTranslation('calcul')) + 1;
        taskSheet.getColumn(calculColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.calculTypes],
                    promptTitle: 'Calculation Type',
                    prompt: 'Suggested values: Duration, Quantity, or Workrate'
                });
            }
        });

        // Add validation for quantity unit
        const quantityUnitColIndex = Object.keys(tasks[0]).indexOf(columnsTranslation('quantity_unit')) + 1;
        taskSheet.getColumn(quantityUnitColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.quantityUnits],
                    promptTitle: 'Quantity Unit',
                    prompt: 'Suggested quantity units available in dropdown'
                });
            }
        });

        // Add validation for calendar
        const calendarColIndex = Object.keys(tasks[0]).indexOf(columnsTranslation('calendar')) + 1;
        taskSheet.getColumn(calendarColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.calendars],
                    promptTitle: 'Calendar',
                    prompt: 'Suggested calendars available in dropdown'
                });
            }
        });

        // Add validation for duration unit
        const durationUnitColIndex = Object.keys(tasks[0]).indexOf(columnsTranslation('duration_unit')) + 1;
        taskSheet.getColumn(durationUnitColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.timeUnits],
                    promptTitle: 'Duration Unit',
                    prompt: 'Suggested duration units available in dropdown'
                });
            }
        });

        // Add validation for jobs
        const jobsColIndex = Object.keys(tasks[0]).indexOf(columnsTranslation('jobs')) + 1;
        taskSheet.getColumn(jobsColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.jobs],
                    promptTitle: 'Jobs',
                    prompt: 'Suggested job values available in dropdown. Multiple values can be separated by semicolons'
                });
            }
        });

        // Add validation for status
        const statusColIndex = Object.keys(tasks[0]).indexOf(columnsTranslation('state')) + 1;
        taskSheet.getColumn(statusColIndex).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 1) {
                setCellValidation(cell, {
                    type: 'list',
                    formulae: [validationRanges.status],
                    promptTitle: 'Status',
                    prompt: 'Suggested status values available in dropdown'
                });
            }
        });
    };

    /**
     * Main export function that coordinates the Excel file creation and download
     */
    const exportData = async () => {
        try {
            // Filter and prepare data
            const tasksToExport = uniqBy(
                window.ganttInstance.serialize().data.filter((task) => {
                    if (task.isExtra) return false;
                    if (task.isResource) return false;
                    if (task.virtual) return false;
                    return true;
                }),
                'identity'
            );
            const linksToExport = uniqBy(window.ganttInstance.serialize().links, 'serverId').map(
                (link) => link.dataAPI
            );
            const tasks = formatExportGanttData(tasksToExport);
            const links = formatLinkData(linksToExport);

            // Create workbook and data sheets first
            const workbook = new ExcelJS.Workbook();
            const taskSheet = workbook.addWorksheet('Tasks');
            const linkSheet = workbook.addWorksheet('Links');

            // Create data sheets
            taskSheet.columns = Object.keys(tasks[0]).map(key => ({ 
                header: key, 
                key,
                width: 15 
            }));
            taskSheet.addRows(tasks);
            if (links.length > 0) {
                linkSheet.columns = Object.keys(links[0]).map(key => ({ 
                    header: key, 
                    key,
                    width: 15 
                }));    
                linkSheet.addRows(links);
            }

            // Create validation sheet last and get validation data
            const validationData = createValidationSheet(workbook);

            // Add validations and styling
            if (links.length > 0) {
                addLinksValidation(linkSheet, links, validationData);
            }
            addTasksValidation(taskSheet, tasks, validationData);
            styleSheets([taskSheet, linkSheet]);
            // Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${planningSelected.name}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log('🚀 ~ file: ExportMenu.jsx ~ exportData ~ error:', error);
            notificationError(t('export_xlsx'), generalTranslation('generic_error'));
        }
    };

    return (
        <>
            <Tooltip title={t('export_xlsx')}>
                <QuickButton
                    type="button"
                    className="inline-block text-center mr-3 bg-primary"
                    onClick={() => exportData()}
                >
                    <DownloadOutlined className="mx-auto" />
                </QuickButton>
            </Tooltip>
        </>
    );
};

export default ExportMenu;
